@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');

.successCarousel-card-main-container{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    align-items:center;
    margin-top:25px;
    margin-bottom: 0px;
    border:1px solid grey;
}
.success-Carousel-card-left-container{
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    width:65%;
    gap:20px;
    padding-left:2%;
}
.successCarousel-card-right-image{
    width:25%;
}

@media (max-width:972px){
    .successCarousel-card-main-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items:center;
        margin-top:25px;
        margin-bottom: 0px;
    }
        .success-Carousel-card-left-container{
            width:100%;
            gap:0px;
        }
        .successCarousel-card-right-image{
            width:70%;
        }
}
@media (max-width:500px){
    .success-Carousel-card-left-container{
        width:100%;
        gap:0px;
    }
    .successCarousel-card-right-image{
        width:70%;
    }
}
.success-Carousel-card-left-title{
    font-size:24px;
    font-weight: bold;
    font-family:"Roboto";
    color:#196AE5;
}
.success-Carousel-card-left-text{
    font-size:16px;
    font-family:"Roboto";
    color:#00000099;
}