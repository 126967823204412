.MobileViewCourseList{
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    list-style: none;
    background-color:#fff;
    margin-top:10px;
    gap:10px;
    padding-left: 0px;
}
@media (min-width:767px){
    .MobileViewCourseList{
        display:none
    }
}
.NavcoursesBasicDoubtsTabsListItemButton1{
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 600;
}
.NavcoursesBasicDoubtsTabsListItemButton1:hover{
   color:#196ae5;
}