@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');

.why-choose-glorious-couse{
  color:#000000DE;
  font-size:40px;
  font-family:"Roboto";
  text-align:center;
  margin:0 0 16px;
  font-weight:600;
}

.home-section-service-image-container{
  width:50%;
  flex-shrink: 0;
}
.home-section-service-image{
  width:100%;
  height:100%
}
@media (max-width:912px){
  .home-section-service-image{
    margin-bottom:24px;
  }
}
.home-section-service-container{
  margin:15px 5% 15px 5%;
  display:flex;
  margin-bottom:30px;
}
.home-section-right-side-container{
  padding-left: 60px;
  padding-right: 60px;
  text-align:center;
  flex-grow:1;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
}
.home-section-service-heading{
    color:#000000;
    font-size:32px;
    font-family:"Roboto";
    margin:0px;
    font-weight:600;
}
.home-section-service-description{
  color:#00000099;
    font-size:20px;
    font-family:"Roboto";
    margin:24px 0px 0px;
}
@media (max-width:912px){
  .home-section-service-description{
    font-size:18px;
  }
}
.home-section-service-stats-container{
  display:flex;
  justify-content: center;
  gap:20px;
}
.home-section-service-stats-item{
  padding:16px 8px 0;
  font-size:15px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-section-service-stats-title{
    color:#000000;
    font-size:20px;
    font-family:"Roboto";
    margin:24px 0px 0px;
}
.home-section-service-stats-subtitle{
  color:#00000099;
  font-size:16px;
  font-family:"Roboto";
  margin:4px 0px 0px;
}
.home-section-service-stats-icon-container{
  background-color: #F8F8F8;
  border-radius:5px;
}
.home-section-service-stats-icon{
  color:#000000;
  font-size:24px;
}
.home-section-service-stats-star{
  color:#ff6600;
  font-size:24px;
}
.home-section-service-container-2{
  margin:15px 5%15px 5%;
  display:flex;
  flex-direction: row-reverse;
  margin-bottom:30px;
}
.world-container-title{
  color:#0F4089;
  font-size:16px;
  font-family:"Roboto";
}
@media (max-width:800px){
  .home-section-service-container{
    display:flex;
    flex-direction: column;
    align-items:center;
  }
  .home-section-service-container-2{
    display:flex;
    flex-direction: column;
    align-items:center;
  }
  .why-choose-glorious-couse{
      font-size:24px;
  }
  .home-section-service-image-container{
    width:100%;
  }.home-section-service-description{
    font-size: 14px;
  }.home-section-service-heading{
    font-size: 24px;
  }.home-section-right-side-container{
    width:100%;
    padding: 0px;
  }
  
}


.home-section-success-stories-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 5% 40px 10%;
}
.home-section-success-stories-text{
  font-weight: bold;
  font-size: 18px;
}
.home-section-sucesss-stories-heading{
  font-weight: bold;
  font-size: 42px;
}
.home-section-success-stories-description{
  font-size: 22px;
}
.home-section-explore-courses-button{
  display: inline-block;
  cursor: pointer;
  background: linear-gradient(#FFF, #FFF) padding-box, linear-gradient(to right,#7cb4e4,#1c74d4,#0e39a9 ) border-box;
  transition: all .5s ease;
    border: 2.5px solid transparent;
    color: #000;
    background-color:transparent;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.25px;
  border-radius: 8px;
  padding: 16px 24px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.home-section-explore-courses-button:hover {
  background: linear-gradient(to right, #196ae5, #196ae5) border-box;
    border: 3px solid transparent;
    color: #FFF;
    transition: all .1s ease;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay:0s;
}

@media (max-width:768px){
  .home-section-success-stories-text{
    font-weight: bold;
    font-size: 14px;
  }
  .home-section-sucesss-stories-heading{
    font-weight: bold;
    font-size: 24px;
  }
  .home-section-success-stories-description{
    font-size: 112x;
  }
  .home-section-sucess-stories-button{
      color: #196ae5;
      background-color: rgba(25,106,229,.05);
      border: none;
      padding:5px 10px 5px 10px;
}
}

.home-section-transformed-careers-container{
  background-image: url("../../Images/banner1.png");
  background-size: cover;
  display: flex;
  margin: 0px 10% 0px 10%;
  border-radius: 15px;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0px 30px;
  margin-bottom: 40px;
}
.home-section-transformed-careers-container1{
  width:60%;
}
.home-section-transformed-careers-heading{
  color: #fff;
  font-size:34px;
  font-family:"Roboto";
  margin:0px 0px 5px;
  font-weight:600;
}
.home-section-transformed-careers-text{
  color:#fff;
  font-size: 18px;
}
.home-section-transformed-careers-buttton{
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  border:2px solid linear-gradient(to right,#7cb4e4,  #1c74d4, #7cb4e4);
  background-color: #fff;
  font-family:"Roboto";
  font-weight:600;
  display: inline-block;
    cursor: pointer;
    transition: all .5s ease;
    color: #196AE5;
  
}
.home-section-transformed-careers-buttton:hover {
  background: linear-gradient(to right, #196ae5, #196ae5) border-box;
  border: 3px solid transparent;
  color: #FFF;
  transition: all .1s ease;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay:0s;

}
.home-section-transformed-carrers-text1{
  font-size: 16px;
  color:#fff;
  font-family:"Roboto";
}

@media (max-width:768px){
  .home-section-transformed-careers-container{
    display: flex;
    margin: 0px 3% 0px 3%;
    border-radius: 15px;
    justify-content: center;
    padding: 30px 0px 30px;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
  }
  .home-section-transformed-careers-container1{
    width:90%;
  }
  .home-section-transformed-careers-heading{
    font-size: 18px;
  }
  .home-section-transformed-careers-text{
    font-size: 14px;
  }
  .home-section-transformed-careers-buttton{
    border: none;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    font-size: 10px;
  }.home-section-transformed-carrers-text1{
    font-size: 12px;
  }
  
}

.home-section-explore-courses-section{
  display: flex;
  align-items: center;
  width:90%;
  margin: 0px 5% 40px 5%;
}
.home-section-explore-courses-section1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: self-start;
  width:50%;
  gap:30px;
}

.home-section-explore-courses-section2{
  width: 50%;
}
.home-section-explore-courses-text{
  font-family:"Roboto";
  font-size:16px;
  color:#00000099;
}

.home-section-explore-courses-image{
  width:100%
}

@media (max-width:768px){
  .home-section-explore-courses-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:90%;
    margin: 10px 5% 40px 5%;
  }
  .home-section-explore-courses-section1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: self-start;
    width:100%;
    gap:30px;
  }
  
  .home-section-explore-courses-section2{
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .home-section-explore-courses-button{
    font-family:"Roboto";
    font-size: 12px;
    padding:12px 20px;
  }
  .home-section-explore-courses-image{
    width:100%;
  }
}
.world-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.world-image{
  width:90%;
  margin:20px 5% 50px 5%;
}
.success-carousel-slider-home{
  max-width: 92vw;
  margin-left: 3%;
  margin-right: 1%;
  padding: 20px 2% 20px 2%;
}
.sucess-home-carousel{
  padding-left: 0px;
}
@media (max-width:767px){
  .success-carousel-slider-home{
    max-width: 85vw;
    margin-left: 7%;
    margin-right: 1%;
    padding: 20px 2% 20px 2%;
  }
}
@media (max-width:512px){
  .home-section-explore-courses-button{
    padding:4px 10px;
  }
}
