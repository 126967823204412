@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');
.course-main-container{
    flex-grow:1;
    margin-top:10px;
}

.course-tab-content-container {
    display: flex;
    background-color:#fff;
    gap:30px;
    margin:50px;
    margin-left: 0px;
    width: 100%;
  }
  
  .course-tab-list {
    width:40%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap:20px;
    box-shadow: #e6f0ff;
  }
  
  .course-tab-list .course-tab-list-item {
    cursor: pointer;
    font-family:"Roboto";
    font-size:16px;
    font-weight:700;
    text-transform: uppercase;
    padding: 20px 20px;
    background-color:#e6f0ff;
    margin-right: 5px;
    border-radius:5px;
    box-shadow: #e6f0ff;
    
  }
  .course-tab-list .course-tab-list-item:hover{
    box-shadow: #e6f0ff;
    color:#196AE5;
    background-color:#e6f0ff;
    transform: translate3d(10px,0px,20px);  
  }
  
  .course-active-tab {
    color:#196AE5;
    position: relative;
    background-color:#e6f0ff !important;
    border-left: 8px solid #0066ff !important;
    box-shadow: #e6f0ff;
  }
  
  .course-content-display {
    padding: 20px;
    background-color: #e6f0ff;
    flex-grow: 1;
    border-radius:5px;
    
  }
.course-List-Topic-heading{
    font-family:"Roboto";
    font-size:20px;
    font-weight:700;
}
.course-Content-list-item{
    font-size:16px;
    font-family:"Roboto";
    color:#6c6c6c;
}
.course-accordion-content-display{
    background-color:#fff;
}


  /* Media Query for Mobile View */
@media (max-width: 767px) {
.course-tab-content-container {
      flex-direction: column;
}
    .course-tab-list {
      flex-wrap: wrap;
      gap:20px;
      min-height:514px;
    }
  
    .course-tab-list .course-tab-list-item {
      flex-grow: 1;
      margin-right: 0;
      margin-bottom: 5px;
      background-color:#e6f0ff;
    }
  
    .course-content-display {
      margin-top: 10px; /* Add some space between tabs and content in mobile view */
    }
    .course-accordion{
        display:flex;
        flex-direction: column;
        
    }
    .course-accordion .course-accordion-header{
        cursor: pointer;
        font-family:"Roboto";
        font-size:14px;
        font-weight:700;
        padding: 20px 20px;
        background-color: #fff;
        margin-right: 5px;
         box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius:5px;
        margin-bottom:10px;
        text-transform: uppercase;
    }
    .course-accordion-header:hover{
       box-shadow: #e6f0ff;
        color:#196AE5 !important;
        background-color:#e6f0ff !important;
    }
    .course-accordion:hover{
        color:#196AE5;
        box-shadow: #e6f0ff;
        background-color:#e6f0ff;
    }
    .course-active-accordion{
        color:#196AE5;
        position: relative;
        background-color:#e6f0ff !important;
        box-shadow: #e6f0ff;
        border-left: 8px solid #196AE5 !important;
    }
.course-accordion-content{
    padding: 20px;
    background-color: #e6f0ff;
    flex-grow: 1;
    margin-bottom:10px;
}
.course-Content-list-item{
  font-size: 12px;
}
}


