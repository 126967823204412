.faq-item {
    width: 100%;
    border: 1px solid #d7dae6;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 14px;
    list-style-type: none;
    padding: 25px;
  }
  
  .question-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor:"pointer"
  }
  
  .question {
    color: #050c14;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .doubt-image{
  font-size:40px;
  color:#196ae5;
  }
  @media screen and (min-width: 768px) {
    .question {
      font-size: 20px;
    }
  }
  
  .answer {
    color: #07090a;
    font-family: 'Roboto';
    font-size: 18px;
  }
  
  @media (max-width: 576px) {
    .answer {
      font-size: 12px;
    }
  }
  
  .button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  @media (max-width: 576px) {
    .doubt-image {
      font-size:25px;
    }
  }
  
  .horizontal-line {
    border: 1.5px solid #196ae5;
  }
  