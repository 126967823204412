.courseFlexibleProgramCard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:500px;
    height:300px;
    box-shadow: #bfbfbf 4px 4px 4px 4px;
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    
}

.courseFlexibleProgramImage{
    color:#196AE5;
    font-size: 70px;
}
.courseFlexibleProgramQuestion{
    font-size: 18px;
    font-family:"Roboto";
    font-weight:700;
}
.courseFlexibleProgramAnswer{
    font-size: 16px;
    color: #222;
    font-family:"Roboto"
}
@media(max-width:768px){
    .courseFlexibleProgramCard{
        height:250px;
    }
    .courseFlexibleProgramQuestion{
        font-size:14px;
    }
    .courseFlexibleProgramAnswer{
        font-size:12px;
    }
}