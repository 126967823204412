.dcourseFlexibleProgramCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 250px;
  margin: 10px;
  box-shadow: #bfbfbf 4px 4px 4px 4px;
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  background-color: #ffffff;
}

.dcourseFlexibleProgramImage {
  color: #196ae5;
  font-size: 70px;
}
.dcourseFlexibleProgramQuestion {
  font-size: 24px;
  font-family: "Roboto";
}
.dcourseFlexibleProgramAnswer {
  font-size: 16px;
  font-family: "Roboto";
}

@media (min-width: 768px) and (max-width: 1100px) {
  .dcourseFlexibleProgramCard {
    width: 150px;
    height: 250px;
  }
}
