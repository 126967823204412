.course-intro-main-container{
    display:flex;
    margin-bottom:20px;
    justify-content: space-around;
}

.course-intro-sub-container{
    display: flex;
    flex-direction: column;
    width:60%;
    gap:10px;
}
@media (max-width:768px){
    .course-intro-sub-container{
        width:100%;
        margin-top:20px;
    }
}
.course-Intro-Heading{
    font-size: 30px;
    font-weight: 500;
}
@media (max-width:768px){
    .course-Intro-Heading{
        font-size: 24px;
        font-weight: 500;
    }
}
.course-intro-batch-container{
    background-color: #e6f0ff;
    width:max-content;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.course-intro-calender-icon{
    color: #196AE5;
    font-size: 18px;
}
.course-intro-calender-heading{
    color: #196AE5;
    font-weight:700;
    margin-left: 10px;
}
.course-intro-list-container{
    display: flex;
    align-items: center;
    padding-left: 0px;
}
.course-intro-list-icon{
    color: rgb(127, 255, 161);
    font-size: 18px;
    margin-right: 20px;
    display:flex;
    align-items:center;
}
.course-intro-list-text{
    font-size: 16px;
    display:flex;
    align-items:center;
}

.course-intro-button-container{
    display: flex;
    align-items: center;
    margin-top:10px;
    margin-bottom:10px;
}
.course-intro-button1{
    padding-left: 20px;
    padding-right: 20px;
    padding-top:10px;
    padding-bottom:10px;
    margin-right: 15px;
    font-weight: 600;
    color:aliceblue;
    border:none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(#FFF, #FFF) padding-box, linear-gradient(to right,#7cb4e4,#1c74d4,#0e39a9 ) border-box;
    transition: all .5s ease;
    border: 2.5px solid transparent;
    color: #000;
    background-color:transparent;
}
.course-intro-button1:hover{
    background: linear-gradient(to right, #196ae5, #196ae5) border-box;
    border: 3px solid transparent;
    color: #FFF;
    transition: all .1s ease;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay:0s; 
}
.course-intro-button2{
    padding-left: 20px;
    padding-right: 20px;
    padding-top:10px;
    padding-bottom:10px;
    border-color: #196AE5;
    font-weight: 600;
    color:#196AE5;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
}
.course-intro-data-main-container{
    display: flex;
    align-items: center;
}
.course-intro-data-sub-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}
.course-intro-data-heading{
    font-size: 30px;
    margin-bottom: 0px;
}
.course-intro-data-text{
    font-size: 16px;
}
.course-side-image-main-container{
    width:min-content;
    padding:30px;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    max-height:max-content;
}
.course-intro-side-image{
    display:flex;
    align-items:center;
    width:100%;
    object-fit: cover;
    border-radius:10px;
}
.course-intro-side-image-desc{
    display:flex;
    align-items:center;
    justify-content: center;
    gap:10px;
}
.arrow-width{
    width:20px;
}
.course-intro-data-analyst{
    font-family:"Roboto";
    font-weight:bold;
    font-size:16px;
    margin-left:10px;

}
@media (max-width:767px){
    .course-intro-main-container{
        display:flex;
        flex-direction:column-reverse;
        justify-content:center;
        align-items:center;
    }
    .course-Intro-Heading{
        font-size: 20px;
    }
    .course-into-calender-heading{
        font-size: 14px;
    }
    .course-intro-list-text{
        font-size: 12px;
    }
    .course-intro-button1{
        font-size: 12px;
    }
    .course-intro-data-heading{
        font-size: 20px;
    }
    .course-intro-data-text{
        font-size: 12px;
    }
}