
.All-container{
    padding-left: 5%;
    padding-right: 5%;
}
.Transform-career-sub-heading{
    font-family: "Roboto";
    font-size:16px;
}
.courseWhyGloriousMindMineMainContainer{
    padding:30px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    padding-top:0px;
}
.courseWhyGloriousMindMineMainHeading{
    font-family: "Roboto";
    font-size:24px;
    font-weight:600;
    margin-bottom:10px;
}
.courseWhyGloriousMindMineSubContainer{
    display:flex;
    gap:15px;  
}
@media(max-width:992px){
    .courseWhyGloriousMindMineSubContainer{
        display:flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        padding-left: 0px;
    }
    .courseWhyGloriousMindMineList{
        display:flex;
        flex-wrap:wrap;
        width:100%;
        justify-content:center;
    }
}
@media(max-width:768px){
    .courseWhyGloriousMindMineSubContainer{
        display:flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        padding-left: 0px;
    }.All-container{
        width:100vw;
        padding-left: 0%;
        padding-right: 0%;
    }
    .courseWhyGloriousMindMineMainHeading{
        font-size:18px;
    }
    .Transform-career-sub-heading{
        font-size: 14px;
    }
}
.courseWhyGloriousMindMineList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:80%;
    gap:10px;
    padding-left: 0px;
    padding-bottom: 0px;
}
@media (max-width:768px){
    .courseWhyGloriousMindMineList{
        display:flex;
        justify-content: center;
    }
}
.courseWhyGloriousMindMineSideImageContainer{
    max-width:35%;
    display:flex;
    align-items:center;
}
.courseWhyGloriousMindMineSideImage{
    width:80%;
    object-fit: cover;
}

.courseFlexibleProgramContainer{
    padding:0px 30px;
    
}
.courseFlexibleProgramMainHeading{
    font-family: "Roboto";
    font-size:24px;
    font-weight:600;
    margin-bottom:16px;
}
@media (max-width:768px){
    .courseFlexibleProgramMainHeading{
        font-size:18px;
    }
}
.courseFlexibleProgramList{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.courseCompaniesHiringContainer{
    padding:30px;
    padding-top:0px;
}
.courseBanner{
    margin-bottom: 30px;
}
.courseCompaniesHiringHeading{
    font-family: "Roboto";
    font-size: 28px;
    font-weight:600;
    margin-bottom:30px;
}
.courseTutionFeeMainContainer{
    padding:0px 30px 30px 30px;
    padding-top:0px;
}
.courseTutionFeeMainHeading{
    font-family: "Roboto";
    font-size:18px;
    font-weight:600;
    margin-bottom:16px;
}
.courseTutionFeeSubContainer{
    padding:0px 30px 30px 30px;
}
.courseTutionFeeListItemContainer{
    gap:10px;
    display:flex;
    align-items:center;
    margin-top:10px;
}
.courseTutionFeeIcon{
    font-size:10px;
    display:flex;
    align-items:center;
}
.courseTutionList{
    font-family: "Roboto";
    font-size:18px;
    display:flex;
    align-items:center;
}
.ButtonContainer{
    display:flex;
    gap:10px;
}
.EnrollNowButton1{
    width:50%;
    background-color:#196AE5;
    border-radius: 10px 40px;
    border:none;
    outline:none;
    padding:13px;
    color:#fff;
    font-weight:bold;
    font-family:"Roboto";
    cursor:pointer;
}
.EnrollNowButton1:hover{
    opacity:0.9;
}
@media (max-width:768px){
    
.courseTutionFeeMainContainer{
    padding:0px 30px 30px 30px;
    padding-top:0px;
}
.courseTutionFeeMainHeading{
    font-family: "Roboto";
    font-size:18px;
    font-weight:600;
    margin-bottom:16px;
}
.courseTutionFeeSubContainer{
    padding:0px 30px 30px 0px;
}
    .courseCompaniesHiringHeading{
    font-family: "Roboto";
    font-size: 18px;
    font-weight:600;
    margin-bottom:16px;
}
    .EnrollNowButton1{
        border-radius:8px;
        padding: 8px;
    }
    .courseTutionList{
        font-size: 12px;
    }
}
.DownloadBroucherButton1{
    width:50%;
    border-radius: 10px 40px;
    border:2px solid #196AE5;
    outline:none;
    padding:11px;
    color:#222;
    font-weight:bold;
    background-color: #fff;
    font-family:"Roboto";
    cursor:pointer;
}
.DownloadBroucherButton1:hover{
    width:50%;
    background-color:#196AE5;
    border-radius: 10px 40px;
    border:none;
    outline:none;
    padding:13px;
    color:#fff;
    font-weight:bold;
    font-family:"Roboto";
    cursor:pointer;
}
.coursesBasicDoubtsTabsList{
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 0px;
}
.coursesBasicDoubtssMainContainer{
    padding:30px;
    padding-top:0px;
}
.coursesBasicDoubtsMainHeading{
    font-family: "Roboto";
    font-size:24px;
    font-weight:600;
    margin-bottom:16px;
}
.coursesBasicDoubtsTabsListItemButton1{
    background-color: #e6f0ff;
    border: none;
    margin-right: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 10px;
    font-family:"Roboto";
    margin-left: 0px;
    font-size: 20px;
}
.courseBasicDoubtActiveTab1{
    background-color: #196AE5;
    color:#fff;
    font-weight:bold;
}
.coursesBasicDoubtsList{
    list-style-type: none;
    width:100%;
    padding-left: 0px;
}
.courseBanner{
    margin-bottom: 30px;
}
.courseTutionFeeListItemContainer{
    display: flex;
    align-items: center;
}
.courseTutionFeeIcon{
    color:#196AE5;
    font-weight: 500;
    font-size: 20px;
}
.courseReviewsMainContainer{
    padding: 30px;
    padding-top: 0px;
}
.courseReviewMainHeading{
    font-family: "Roboto";
    font-size:24px;
    font-weight:600;
    margin-bottom:16px;
}
.courseReviewList{
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    padding-left: 0px;
}
.slider-container{
    margin:30px;
}

@media screen and (max-width: 1200px) {
    .courseReviewMainHeading{
        font-size: 18px;
    }
    .coursesBasicDoubtsTabsListItemButton1{
        padding:10px 20px;
        font-size: 12px;
        border-radius: 4px;
    }
    .slider-container .slick-slide {
      display: block;
    }
    .coursesBasicDoubtsMainHeading{
        font-size: 18px;
    }
  }
@media screen and (max-width: 768px) {
    .slider-container .slick-slide {
      display: flex;
      justify-content: center;
}
.courseFlexibleProgramList{
    margin-left: 0px;
    padding-left: 0px;
    list-style-type: none;
}.courseWhyGloriousMindMineSideImageContainer{
    width:100%;
}.courseWhyGloriousMindMineList{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    list-style-type: none;
    padding-left: 0px;
    }
}

.courseTransformingcarrercarouselcontainer{
    max-width:100%;
}
@media (max-width:768px){
    .courseTransformingcarrercarouselcontainer{
        max-width:90%;
    }.courseWhyGloriousMindMineSideImageContainer{
        max-width:90%;
        display:flex;
        align-items:center;
    }
    .courseWhyGloriousMindMineSideImage{
        width:80%;
        object-fit: cover;
    }
}
@media (min-width:450px) and (max-width:750px){
    .courseWhyGloriousMindMineSideImageContainer{
        width:50%;
    }
}