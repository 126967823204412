@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.nav-header {
      display: flex;
      flex-direction: column;
      align-items:center;
      background-color: #ffffff;
      flex-shrink: 1;
    }
    @media screen and (max-width: 768px) {
      .nav-header {
        flex-direction: column;
        align-items: center;
        border-bottom-style: none;
      }
    }
    .nav-content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width:100%;
      height: 64px;
      background-color:#fff;
      box-shadow: 0px 10px 10px -15px #111; 
      flex-shrink:1; 
    }
  @media (max-width: 767px) {
      .nav-content {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
    
    .nav-bar-mobile-logo-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .nav-mobile-logo{
      margin-top: 0px;
      width:90px;
    }
    @media screen and (min-width: 768px) {
      .nav-bar-mobile-logo-container {
        display: none;
      }
    }
    
    .nav-bar-website-logo-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 160px;
      text-decoration: none;
    }
    
    .nav-website-name {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #196AE5;
    }
    
    @media screen and (min-width: 768px) {
      .nav-bar-website-logo-link {
        width: 170px;
      }
      .nav-website-name {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .nav-bar-large-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding:0px 20px 0px 0px;
      align-items: center;

    }
    .containernavbar{
      display:flex;
      justify-content: space-between;
      align-items:center;
      align-self:center;
      gap:20px;
      margin-left: 20px;
    }
    
    @media screen and (max-width: 767px) {
      .nav-bar-large-container {
        display: none;
      }
    }
    
    .nav-website-logo {
      width: 140px;
      margin-top: 0px;
      padding:30px 0px 30px 0px;
    }
    @media (max-width: 1000px) {
      .nav-website-logo {
        width: 80px;
        margin-top: 0px;
        display:flex;
        align-items:center;
        align-self:center;
      }
    }
    
    .nav-mobile-btn {
      background-color: transparent;
      padding: 0px;
      border: none;
      cursor: pointer;
      outline: none;
    }
    
    .nav-mobile-menu-icon {
      font-size: 24px;
    }
    
    .nav-menu {
      margin-left: 0px;
      padding-left: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: center;
      flex: 1;
      list-style-type: none;
      align-self: center;
      margin-bottom: 0px;
      font-weight: 600;
    }
    .nav-menu-mobile{
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      align-self: flex-end;
      flex: 1;
      list-style-type: none;
      gap:20px;
      padding-top:20px;
      max-width:max-content;
      padding-left: 0px;
    }
    
    @media (min-width:800px){
      .nav-menu-mobile{
        display:none;
      }
    }
    .nav-menu-item {
      margin-right: 4px;
      font-family:"Poppins" !important;
      font-size:14px;
      font-weight:550;
      letter-spacing:0.5px;
      width:max-content;
    }
   
    .nav-menu-item:hover{
      color:#196AE5;
    }
    .nav-menu-item:active{
      color:#196AE5
    }
    @media (max-width:1200px){
      .nav-menu-item{
        font-size:12px;
        flex-shrink: 1;
        width:max-content;
        margin-right: 0px;
      }
    }
      @media (min-width:1200px){
        .nav-menu-item{
          font-size:14px;
          flex-shrink: 1;
          width:max-content;
        }.nav-menu{
          gap:10px
        }
    }
    .nav-link {
      text-decoration: none;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #262626;
    }
    
    .logout-desktop-btn {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 550;
      font-size: 14px;
      font-weight:bold;
      line-height: 16px;
      color: #ffffff;
      background-color: #196AE5;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      outline: none;
      width:max-content;
      font-family: "Poppins";
      padding: 8px 10px;
    }
    @media (max-width:912px){
      .logout-desktop-btn{
        font-size:12px;
        padding:4px 10px 4px 10px;
        margin-left: 5px;
      }
    }
      @media (max-width:1200px){
        .logout-desktop-btn{
          font-size:12px;
        }
    }
    
    @media (max-width: 767px) {
      .nav-items-container {
        transition-delay: 1s;
        transition: top 2s ease-in-out;
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        min-width: 100vw;
        max-width: 100vw;
        padding-left: 2%;
        padding-right: 2%;
        position: absolute;
        z-index: 2;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
      }
      .nav-menu {
        padding-left: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
      }
      .nav-mobile-close-button {
        background-color: transparent;
        outline: none;
        border: none;
        font-size: 20px;
      }
    }
    .navItemActive {
      color: #196AE5;
    }
    .dropdown{
      position:absolute;
      top:100%;
      left:0;
      width:100%;
      padding:0;
    }
    .Explore-program {
      position: relative;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; 
      align-items: center;
      margin-top:4%;
      padding-left: 0px;
    }    
    @media (min-width:1100px){
      .Explore-program{
        margin-left:20px;
      }
    }
    .display-content{
      position: absolute;
      list-style-type:none;
      visibility: hidden;
      display:flex;
      justify-content:flex-start;
      top:20px;
      padding-top:33px;
      margin-left:40vw;
      height:max-content;
      width:max-content;
    }
    
    .Explore-program:hover .display-content{
      visibility: visible;
      z-index:999;
      
    }
  
.Explore-program-item-name:hover{
      border:1px solid #196AE5;
      padding:10px;
      border-radius: 5px;
}
.explore-program-main-container{
    display:flex;
    background-color:#fff;

}

.NavcoursesBasicDoubtsTabsList{
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        padding-top:40px;
        padding-bottom:40px;
        list-style-type: none;
        width:300px;
        border-radius:5px; 
        background-image: linear-gradient(30deg,#0e39a9,#1c74d4);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;     
}
.NavcoursesBasicDoubtsTabsListItemButton {
    border:none;
    width:200px;
    padding:10px;
    background-color: transparent;
    font-family:"Roboto";
    font-weight:550;
    cursor:pointer;
    display:flex;
    align-self: flex-start;
    color:#fff;
    
}
.NavcourseslistContainer{
  font-size: 15px;
}
.NavcourseslistContainer:hover{
  font-size: 17px;
  font-weight: bold;
  border: 2px solid #fff;
  border-radius: 5px;
  width:max-content;
  padding-right: 10px;
  transition: 0.5s;
}
/* .NavcoursesBasicDoubtsTabsListItemButton:hover{
  color:#fff;
  font-size: 16px;
} */

@media (max-width:1200px){
  .display-content{
    margin-left:45vw;
  }
}
@media (max-width:912px){
  .display-content{
    margin-left:50vw;
  }
}
.Mobile-explore-items-list{
  display:none;
}
.Mobile-explore-sub-container{
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  list-style-type:none;
}
.Mobile-explore-container:hover .Mobile-explore-items-list{
  display:flex;
  flex-direction: row-reverse;
}
.explore-program-item{
  background-color:#196AE5;
  color:#fff;
  font-weight:600;
  padding:8px 10px 8px 10px;
  border-radius:4px;
  font-size: 14px;
  margin-top: 4px;
}
.explore-program-item:hover{
  border:2px solid #196AE5;
  background-color: #fff;
  color:#196AE5;
}

.NavcourseslistContainer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.NavcourseslistContainer:hover{
  color:#195AE5;
}
.NavcoursesBasicDoubtsTabsDetailsContainer{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.NavcoursesBasicDoubtsTabsListItemArrow{
  display:flex;
  justify-content: center;
  align-items:center;
  align-self:center;
  color:#e6f0ff
}

.active{
  color:#196AE5 !important;
}

.nav-explore-course-main-container1{
 max-width:max-content;
 padding-top:20px;
}
.nav-expolre-list-1{
  list-style-type: none;
  padding-left: 0px;
}
.nav-explore-course-main-container-item1{
  text-decoration: none;
}
.nav-explore-course-main-container-item2{
  color:#222;
  list-style-type: none;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 25px;
}
@media (min-width:797px){
  .nav-explore-course-main-container1{
    display: none;
  }
  }
  @media (max-width:1100px){
    .explore-program-item{
      margin-top: 4px;
      padding: 8px 10px;
      font-size: 12px;
    }
  }

  @media (min-width:767px) and (max-width:930px){
    .nav-content {
      padding-left: 0%;
      padding-right: 0%;
    }.explore-program-item{
      margin-top: 4px;
      padding: 4px;
      font-size: 10px;
    }.nav-menu-item{
      font-size: 10px;
    }.logout-desktop-btn{
      font-size: 10px;
      padding:4px;
    }

  }
@media (min-width:1100px) and (max-width:1200px){
  .nav-menu{
    gap:15px;
  }
}
@media (min-width:1767px) and (max-width:912px){

  .nav-menu{
    gap:15px;
  }
}
@media (min-width:767px){
  .nav-menu{
    gap:5px;
  }
}
@media (min-width:820px){
  .nav-menu{
    gap:10px;
  }
}
@media (min-width:930px){
  .nav-menu{
    gap:12px;
  }
}
@media (min-width:1200px){
  .containernavbar{
     gap:15px;
     margin-left: 30px;
   }.nav-menu{
     gap:8px;
   }
  }
@media (min-width:1300px){
 .containernavbar{
    gap:40px;
    margin-left: 30px;
  }.nav-menu{
    gap:20px;
  }
}
@media (min-width:1400px){
  .containernavbar{
     gap:70px;
     margin-left: 30px;
   }.nav-menu{
     gap:20px;
   }
 }