.HireFromusCard1Container{
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    width:180px;
    height:220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    text-align:center;
    margin: 10px;
}
.HirefromusCard1Image{
    width:130px;
}
.HirefromusCard1Name{
    margin-top: 10px;
    font-weight: 600px;
    font-size: 16px;
    margin-bottom: 5px;
}
.HirefromusCard1Text{
    font-weight: 600px;
    font-size: 18px;
    margin-top: 0px;
}

@media (min-width:797px) and (max-width:1100px){
    .HireFromusCard1Container{
        margin: 0px;
        width:150px;
        margin-bottom: 10px;
        height:max-content;
    }.HirefromusCard1Name{
        margin-top: 5px;
        font-size: 14px;
    }.HirefromusCard1Text{
        font-size: 16px;
        margin-bottom: 0px;
    }.HirefromusCard1Image{
        width:120px;
    }
}