
.imgright {
  width: 160px;
  max-width: 250px;
  height: 50px;

    padding: 0 15px;

    object-fit: cover;

  }

 

.imgright:last-of-type {

    padding-left: 0;

  }

 

.inner {

    position: relative;

    width: 100%;

    overflow: hidden;

    height: 5rem;

  }

 

  .wrapper {

    position: absolute;

    display: flex;

  }

 

  .section {

    display: flex;

    animation: swipe var(--speed) linear infinite reverse backwards;

  }

 

  @keyframes swipe {

    0% {

      transform: translate(0);

    }

 

    100% {

      transform: translate(-100%);

    }

  }

 

  /*.img {

    max-width: 200px;

    height: 60px;

    padding: 0 15px;

    object-fit: cover;

  }

 

  .img:last-of-type {

    padding-left: 0;

  }

 

  .inner {

    position: relative;

    width: 100%;

    overflow: hidden;

    height: 5rem;

  }

 

  .wrapper {

    position: absolute;

    display: flex;

  }

 

  .section {

    display: flex;

    animation: swipeRight var(--speed) linear infinite backwards;

  }

 

  @keyframes swipeRight {

    0% {

      transform: translate(0);

    }

 

    100% {

      transform: translate(-100%);

    }

  }

 

  .section:nth-child(2) {

    animation: swipeLeft var(--speed) linear infinite backwards;

  }

 

  @keyframes swipeLeft {

    0% {

      transform: translate(0);

    }

 

    100% {

      transform: translate(100%);

    }

  }*/

 

 