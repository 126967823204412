@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');
.ielts-second-page-what-is-ielts{
    color: #196AE5;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.ielts-second-page-paragraph-body{    
    font-family: "Roboto";
    font-size: 16px;      
    color: #333;    
}

.ielts-second-page-second-heading{
    color: #196AE5;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.ielts-margin{
    padding: 1% 5% 2% 5%;
}

.ielts-score-table{     
    width: 100%;       
    border-collapse: collapse;      
    margin-bottom: 20px;     
    font-family: "Roboto";
    font-size: 16px;      
    color: #333;    
       
    }       
          
  .ielts-score-table-heading, .ielts-score-table-row-text {          
    padding: 10px;       
    text-align: left;       
    border-bottom: 1px solid #ddd;    
  
  }           
     
  .ielts-score-table-heading {     
    background-color: #f2f2f2;       
 } 
   
 

     
.ielts-score-table-row:hover {     
    background-color: #e0e0e0;    
    }
  
.table-container{  
    border: 1px;  
    border-style: solid;  
  }

.ielts-margin-container{
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (min-width:320px) and (max-width: 567px){
    .ielts-score-table {     
        width: 80%;       
        border-collapse: collapse;      
        margin-bottom: 20px; 
        color: #333;
        font-family: "Roboto";
        font-size: 16px;      
        color: #333;           
        }   
        .ielts-second-page-what-is-ielts{            
            font-size:24px;            
        }
        
        .ielts-second-page-paragraph-body{            
            font-size: 16px;                  
        }
        
        .ielts-second-page-second-heading{           
            font-size: 24px;
            
        }
        .ielts-margin{
           margin: 0px;
        }
}
