@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');


.courseTransformingcard{
    width:400px;
    height:max-content;
    border:1px solid lightgrey;
    border-radius:10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 0 auto;
}

.courseTransformingContainer1{
    background-color:#e6f0ff;
    min-height:120px;
    max-height:110px;
    font-style:oblique;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.courseTransformingDescription{
    font-size: 14px;
    font-weight:600;
    color:#222;
}
.courseTransformingContainer2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px;
    padding-bottom:0px;
    color:#222;
    height: max-content;
}
.courseTransormingContainer2-1{
    display: flex;
    flex-direction: column;
    color:#222;
}
.courseTransformingName{
    font-family:"Roboto";
    font-size:16px;
    font-weight:500;
    color:#222;
    font-weight:600;
}
.courseTransformingPrviousrole{
    font-family:"Roboto";
    font-size:14px;
    font-weight:500;
    color:#222;
    margin-bottom:5px;
}
.courseTransformingArrow{
    font-size:30px;
    color:#196AE5;
}
.courseTransformingRole{
    font-family:"Roboto";
    font-size:14px;
    font-weight:500;
    color:#222;
    margin-top:5px;
    margin-bottom:0px;
}
.courseTransformImage{
    width:80px;
    height:80px;
    border-radius:50%;
    align-self:flex-start;
}
.courseTransformingContainer3{
    display: flex;
    justify-content: space-between;
    padding:20px;
    padding-bottom:0px;
    color:#222;
}
.courseTransformingCompany{
    font-family:"Roboto";
    font-size:14px;
    font-weight:600;
    color:#222;
}
.courseTransformingHike{
    font-family:"Roboto";
    font-size:14px;
    font-weight:500;
    color: #00AE8C;
    font-weight:800;
}
@media (max-width:1000px){
    .courseTransformingcard{
        display:block;
        width:300px;
        height:max-content;
    }
}
@media (max-width:767px){
    .courseTransformingcard{
        display:block;
        width:90%;
        height:max-content;
    }
    .courseTransformingContainer1{
        max-height:max-content;
     
    }
    .courseTransformImage{
        width:40px;
        height:40px;
        border-radius:50%;
        align-self:flex-start;
    }
}
@media (max-width:300px){
    .courseTransformingcard{
        width:100%;
        height:max-content;
       
    }
    .courseTransformingContainer1{
        max-height:max-content;
       
    
    }
}