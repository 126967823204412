.Social-icons-list{
    margin-top: 10px;
    top: 80px; /* Adjust this value to set the desired distance from the bottom */
    left: 40px; 
    display: flex;
    gap:10px;
    justify-content: flex-end;
    margin-bottom: 10px;
}

@media (max-width:768px){
    .Social-icons-list{
        margin-top: 10px;
        top: 100px; /* Adjust this value to set the desired distance from the bottom */
        left: 20px; 
        display: flex;
        justify-content: flex-end;
        gap:5px;
    }
    .social-logo1{
        width:16px;
        height:16px;
    }
}