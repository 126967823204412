.aboutus-page-main-container {
  padding: 0px 5%;
}

.aboutus-page-banner-container {
  width:100%;
  display: flex;
  gap:50px;
  margin-bottom:24px;
}

.aboutus-page-banner-content-container {
  width: 50%;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.aboutus-page-banner-container-heading {
  font-size: 32px;
  line-height: 40px;
  margin-top: 15px;
  margin-bottom: 24px;
  font-weight:600;
  font-family:"Roboto";
  margin-bottom:4px;
}

.aboutus-page-banner-container-content {
  font-size: 20px;
  margin: 30px;
  font-family:"Roboto";
  color:#222;
  margin-top: 0px;
}

.aboutus-page-banner-container-image {
  width: 45%;
  padding: 10px;
  border-radius: 20px;
}
.aboutus-page-banner-container-2{
  width:100%;
  display: flex;
  justify-content: space-between;
  gap:50px;
  margin-bottom:24px;
}
.aboutus-page-banner-container-image-2{
  width: 45%;
  padding: 10px;
  border-radius: 20px;
}
.aboutus-page-banner-content-container-2{
  width: 50%;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  
}
.about-us-why-choose-glorious-heading{
  font-size:32px;
  font-weight:600;
  font-family:"Roboto";
  text-align:center;
  margin-bottom:20px;
  margin-top:24px;
}
.about-us-why-choose-glorious-description-container{
  display:flex;
  flex-direction: column;
  gap:20px;
  padding-left:0px;
}
.about-us-why-choose-glorious-sub-heading{
  font-size:18px;
  font-weight:600;
  font-family:"Roboto";
  color:#196AE5;
  
}
.about-us-why-choose-glorious-description{
  font-size:18px;
  font-family:"Roboto";
  color:#222;
  list-style-type: none;
}
.about-us-last-message{
  font-size:20px;
  font-family:"Roboto";
  color:#196AE5;
  text-align:center;
  font-weight:600;
  margin-bottom:20px;
}
@media (max-width:1200px){
  .aboutus-page-banner-content-container{
    width:60%
  }
  .aboutus-page-banner-container-image{
    width:38%;
    height:100%;
    align-self:center;
  }
  .aboutus-page-banner-container-content{
    font-size:14px;
  }
  .aboutus-page-banner-container-image-2{
    width:38%;
    height:100%;
    align-self:center;
  }
  .aboutus-page-banner-content-container-2{
    width: 60%;
    
  }
}
@media (max-width:912px){

  .aboutus-page-banner-content-container{
    width:60%
  }
  .aboutus-page-banner-container-image{
    width:38%;
    height:100%;
    align-self:center;
  }
  .aboutus-page-banner-container-content{
    font-size:14px;
  }
  .aboutus-page-banner-container-heading{
    font-size:24px;
  }
  .about-us-why-choose-glorious-heading{
    font-size:24px;
  }
  .about-us-why-choose-glorious-sub-heading{
    font-size:16px;
  }
  .about-us-why-choose-glorious-description{
    font-size:14px;
  }
  .about-us-last-message{
    font-size:16px;
    font-family:"Roboto";
    color:#196AE5;
    text-align:center;
  }
}

@media (max-width: 768px) {
  .aboutus-page-banner-container {
    flex-direction: column;
    gap:10px;
  }
  .aboutus-page-banner-container-2 {
    flex-direction: column-reverse;
    gap:10px;
  }
  .aboutus-page-banner-container-heading{
    font-size:24px;
  }
  .aboutus-page-banner-content-container{
    width:100%;
  }
  .aboutus-page-banner-container-image{
    width:80vw;
  }

  .aboutus-page-banner-conatiner-heading {
    font-size: 24px;
    line-height: 36px;
    margin-top: 10px;
  }

  .aboutus-page-banner-conatiner-content {
    font-size: 13px;
    line-height: 26px;
    margin-top: 8px;
  }
  .aboutus-page-banner-container-image-2{
    width:80vw;
    height:100%;
    align-self:center;
  }
  .aboutus-page-banner-content-container-2{
    width: 100%;
    
  }
  .about-us-why-choose-glorious-heading{
    font-size:24px;
  }
  .about-us-why-choose-glorious-sub-heading{
    font-size:16px;
  }
  .about-us-why-choose-glorious-description{
    font-size:14px;
  }
}

.about-us-why-choose-glorious-values-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  background-color: #f0f0f0;
}

.about-us-why-choose-glorious-value-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  text-align: center;
}

.about-us-why-choose-glorious-value-heading {
  font-size: 24px;
  color: #196AE5;
  margin-bottom: 10px;
  font-family:"Roboto";
  font-weight:600;
}

.about-us-why-choose-glorious-value-description {
  font-size: 16px;
  color: #222;
  font-family:"Roboto";
}


@media (min-width: 913px) and (max-width: 1200px) {
  
  .about-us-why-choose-glorious-values-container {
    justify-content: space-between;
  }

  .about-us-why-choose-glorious-value-card {
    width: calc(20% - 10px);
  }
}
@media (min-width: 769px) and (max-width: 912px) {
 
  .about-us-why-choose-glorious-values-container {
    justify-content: center;
  }

  .about-us-why-choose-glorious-value-card {
    width: calc(50% - 10px);
  }
}
@media (min-width: 513px) and (max-width: 768px) {
  .about-us-why-choose-glorious-values-container {
    flex-direction: column;
    align-items:center;
  }

  .about-us-why-choose-glorious-value-card {
    width: 100%;
  }
}
@media (max-width:512px){
  .about-us-why-choose-glorious-value-card {
    
    min-width:200px;
    max-width:350px;
  }
  .about-us-why-choose-glorious-value-heading{
    font-size:16px;
  }
  .about-us-why-choose-glorious-values-container {
    flex-direction: column;
    align-items:center;
  }
}
