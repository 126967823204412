
.study-abroad-popup-main-container{
    display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.study-abroad-popup-container{
    position: relative;
    background-color: #e6e6ff;
    width:900px;
    max-width: 900px;
    max-height:500px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.study-abroad-popup-container1{
    display: flex;
    justify-content: center;
    align-items: center;
}
.study-abroad-popup-icon-container{
    border-radius: 50%;
    background-color: #fff;
    padding: 15px;
}
.study-abroad-popup-icon{
    font-size: 50px;
    color:#196AE5;
}
.study-abroad-popup-icon-right-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
}
.study-abroad-popup-icon-right-container-heading{
    font-size: 24px;
    font-weight:600;
    font-family:"Roboto";
    color:#196AE5;

}
.study-abroad-popup-icon-right-container-text{
    font-size: 18px;
}
.study-abroad-popup-inputs-container{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    align-items: center;
}
.study-abroad-popup-inputs-icon-container{
    display:flex;
    align-items: center;
    width:250px;
    height:40px;
    margin: 10px;
    font-size:16px;
    font-family:"Roboto";
    border-radius:5px;
    background-color: #fff;
    padding:2px 10px;
}
.study-abroad-popup-inputs-icon-container:hover{
    border:1px solid #196AE5;
}
.study-ppr-popup-input{
    border:none;
    margin-left:5px;
    outline:none;

}
.study-abroad-popup-input-icon{
    color:#196AE5;
}
.study-ppr-popup-select{
    width:100%;
    height:95%;
    margin: 10px;
    font-size:16px;
    font-family:"Roboto";
    border-radius:5px;
    background-color: #fff;
    padding:2px 10px;
}
.study-ppr-popup-select-option{
    margin-bottom:5px;
}
.study-ppr-popup-select-option:checked{
    background-color:#196AE5;
    color:#fff;
}
.study-ppr-popup-select-option option:hover{
    background-color:red !important;
    color:#fff;
}
.study-abroad-popup-button{
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(#FFF, #FFF) padding-box, linear-gradient(to right,#7cb4e4,#1c74d4,#0e39a9) border-box;
    transition: all .5s ease;
    border: 2.5px solid transparent;
    border-radius: 10px;
    background-color:transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 1.25px;
    color: #222;
    padding: 10px 24px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    margin-top:10px;
}
.study-abroad-popup-button:hover {
    background: linear-gradient(to right, #196ae5, #196ae5) border-box;
    border: 3px solid transparent;
    color: #FFF;
    transition: all .1s ease;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay:0s;
  
  }

@media (max-width:992px){
    .study-abroad-popup-container{
        position: relative;
        background-color: #e6e6ff;
        width:85vw;
        max-width: 450px;
        max-height:640px;
        border-radius: 10px;
        padding: 0px;
    }
.study-abroad-popup-container1{
    margin-bottom:0px;
    margin: 0px 10px 0px 10px;
}.study-abroad-popup-inputs-icon-container{
    width:100%;
    height:35px;
    margin: 5px;
    font-size:14px;;
    padding:2px 5px;
}
.study-abroad-line1{
    margin-bottom: 10px;
}
    .study-abroad-popup-inputs-container{
        display: flex;
        flex-wrap: wrap;
        gap:0px;
        align-items: center;
        margin-top: 0px;
        padding: 0px 20px 0px 20px;
        margin-bottom: 0px;
    }
.study-abroad-popup-icon{
    font-size: 40px;
}
.study-abroad-popup-icon-right-container{
    margin-left: 20px;
}
.study-abroad-popup-icon-right-container-heading{
    font-size: 18px;
}
.study-abroad-popup-icon-right-container-text{
    font-size: 14px;
    padding: 0px;
}.study-abroad-popup-inputs-container{
    align-items: center;
    width:100%;
    justify-content: center;
}
.ppr-popup-input{
    width:100%;
    height:40px;
}
.study-abroad-popup-button{
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}.study-abroad-popup-close-button {
    margin-right: 0px;
    margin: 0px;
    padding: 0px;
  }.study-abroad-line1{
    margin: 0px;
    margin-bottom: 10px;
  }
}

.study-abroad-popup-close-button {
    align-self: flex-end;
    font-size: 34px;
    cursor: pointer;
    color: #196AE5;
    margin: 0px;
    padding: 0px;
  }

  .studyabordpopup-response{
    font-size: 14;
    margin: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }