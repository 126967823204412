/* Default styles */
*{
  font-family:"Roboto";
}
.enterprise-banner-section {
    display: flex;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #f8f8f8;
    
  }
  
  .enterprise-banner-section-conatiner {
    display: flex;
    flex-direction: column;
    width:50%;
    padding: 25px;
    padding-left: 55px;
    justify-content: center;
  }
  
  .enterprise-banner-section-image {
    width: 40%;
    margin: auto;
    padding: 2% 0% 2% 3%;
  }
  
  .enterprise-banner-section-title {
    color: black;
    font-size: 32px;
    line-height: 60px;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: .25px;
    font-family:"Roboto";
    font-weight:600;
  }
  
  .enterprise-banner-section-subtitle {
    font-size: 16px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 16px;
    font-weight: 400;
  }
 

.enterprise-banner-section-button {
  display: inline-block;
  cursor: pointer;
  background: linear-gradient(#FFF, #FFF) padding-box, linear-gradient(to right,#7cb4e4,#1c74d4,#0e39a9 ) border-box;
  transition: all .5s ease;
  border: 2.5px solid transparent;
  color: #000;
  background-color:transparent;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 22px;
}

.enterprise-banner-section-button:hover {
  background: linear-gradient(to right, #196ae5, #196ae5) border-box;
  border: 3px solid transparent;
  color: #FFF;
  transition: all .1s ease;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay:0s;
/* Apply the same border-radius on hover */
}


  .enterprise-banner-section-change-text{
    font-size: 24px;
    line-height: 32px;
    letter-spacing: .15px;
    color: #196ae5;
    font-weight: 600;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .enterprise-banner-section-conatiner {
      display: flex;
      flex-direction: column;
      width:100%;
      padding: 25px;
      padding-left: 25px;
      justify-content: center;
    }
    /* Adjust the styles to fit smaller screens */
    .enterprise-banner-section {
      flex-direction: column;
    }

    .enterprise-banner-section-title {
      font-size: 24px;
      line-height: 40px;
    }
    .enterprise-banner-section-change-text{
      font-size: 18px;
      line-height: 32px;
      letter-spacing: .15px;
      color: #196ae5;
      font-weight: 600;
    }
    .enterprise-banner-section-image {
      width: 100%;
      margin-bottom: 20px;
      padding: 5%;
    }
  
    .enterprise-banner-section-button {
     width: 140px;
     padding: 6px 12px;
     font-size: 15px;
    }
  }

  .enterprise-industry-section{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    text-align: center;
  }

  .enterprise-industry-section-certificate-conatiner{
    background-color: #f2f2f3;
    display: flex;
    margin: 30px;
  }
   
  .enterprise-industry-section-image{
    width: 200px;
    padding: 20px;
  }

  .enterprise-industry-section-certificate-conatiner-content{
    width:60%;
    text-align: start;
    padding: 10px;
  }

  .enterprise-industry-section-certificate-heading{
    margin-bottom: 8px;
    color: #196ae5;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: .15px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  .enterprise-industry-section-certificate-content{
    line-height: 1.6em;
    font-weight: 400;
    font-size: 16px;
    color: #444444;
    
  }

  @media (max-width: 768px) {
    /* Adjust the styles to fit smaller screens */
    .enterprise-industry-section-certificate-conatiner {
      flex-direction: column; /* Stack the content vertically */
      padding: 10px;
    }
  
    .enterprise-industry-section-image {
      width: 100%;
      padding: 10px;
    }
  
    .enterprise-industry-section-certificate-conatiner-content {
      width: 100%;
      text-align: center;
    }
  }

  .enterprise-partners-section-conatiner{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 40px;
  }

  .enterprise-partners-section-title{
    color: black;
    font-size: 32px;
    line-height: 60px;
    margin-top: 10px;
    margin-bottom: 15px;
    letter-spacing: .25px;
    font-weight:600;
  }
  .enterprise-partners-section-conatiner-subtitle{
    margin-bottom: 8px;
    color: #196ae5;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: .15px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  .enterprise-partners-section-cards-conatiner{
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-left: auto;
    margin-right: auto;
    height:max-content;
    margin: 10px;
  }

  .enterprise-partners-section-card-conatiner:hover {
    background-color: #196ae5;
  }

  .enterprise-partners-section-card-conatiner:hover h1,
.enterprise-partners-section-card-conatiner:hover p {
    color: white;
}
  .enterprise-partners-section-card-conatiner{
    width: 310px;
    margin: auto;
    cursor: pointer;
  }

 
  .enterprise-partners-section-card-image{
    width: 100%;
  }
  .enterprise-partners-section-card-content{
    margin: 10px;
    width: 100%;
    height:120px;
  }
  .enterprise-partners-section-card-content-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: .15px;
    color: rgba(0,0,0,.8);
  }
.enterprise-partners-section-card-content-sub-title{
  font-size: 16px;
    line-height: 24px;
    color: rgba(0,0,0,.6);
    margin-top: 8px;
    font-weight: 400;
}



/* Responsive styles */
@media (max-width: 768px) {
  /* Adjust the styles to fit smaller screens */
  .enterprise-partners-section-title {
    font-size: 24px;
    line-height: 40px;
  }
  
  .enterprise-partners-section-conatiner-subtitle {
    font-size: 16px;
    line-height: 20px;
  }
  
  .enterprise-partners-section-cards-conatiner {
    justify-content: center; /* Center the cards */
  }

  .enterprise-partners-section-card-conatiner {
    width: 90%; /* Make the cards 90% of the container width */
    height: auto; /* Allow the height to adjust based on content */
    margin: 15px auto; /* Center the cards */
  }

  .enterprise-partners-section-card-image {
    width: 100%;
    height: auto; /* Allow the image height to adjust based on width */
  }
  .enterprise-partners-section-card-content{
    height: max-content;
  }

  .enterprise-partners-section-card-content-title {
    font-size: 18px;
    line-height: 24px;
  }

  .enterprise-partners-section-card-content-sub-title {
    font-size: 14px;
    line-height: 20px;
  }
}

.enterprise-consultative-section-conatiner{
  width: 100%;
  margin-top: 40px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.enterprise-consultative-section-cards-container{
  display: flex;
  flex-wrap: wrap;
}

.enterprise-consultative-section-card-container{
  display: flex;
  width: 600px;
  margin: auto;
  align-items:flex-start;
}
.enterprise-consultative-section-card-image-container{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #61438e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  
}
.enterprise-consultative-section-card-container-content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
}
.enterprise-consultative-section-card-container-content-title{
    font-size: 24px;
    margin-left: 30px;
    text-align: left;
    padding-right: 16px;
    letter-spacing: -.7px;
    color: #222;
    font-family: "Roboto";
    font-weight:600;
}
.enterprise-consultative-section-card-container-content-list-head{
  margin-left: 35px;
  color: #000000DE;
  margin-top: 0px;
  margin-bottom: 0px;
}
.enterprise-consultative-section-card-container-content-ul{
  margin-left: 25px;
  color: #00000099;
}

.enterprise-partners-section-button{
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(#FFF, #FFF) padding-box, linear-gradient(to right,#7cb4e4,#1c74d4,#0e39a9 ) border-box;
    transition: all .5s ease;
    border: 2.5px solid transparent;
    color: #000;
    background-color:transparent;
    font-size: 18px;
    letter-spacing: 1.25px;
    line-height: 16px;
    padding: 20px 40px;
    text-transform: uppercase;
    border-radius: 4px;
    text-align: center;
    margin-top: 25px;
    font-weight: 600;
    width:max-content;
}
.enterprise-partners-section-button:hover {
  background: linear-gradient(to right, #196ae5, #196ae5) border-box;
  border: 3px solid transparent;
  color: #FFF;
  transition: all .1s ease;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  transition-delay:0s;
/* Apply the same border-radius on hover */
}
.enterprise-partnership-section-container{
  width: 100%;
  margin-top: 40px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.enterprise-partnership-section-conatiner-title{
    color: black;
    font-size: 32px;
    line-height: 60px;
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: .25px;
    font-weight:600;
}

.enterprise-partnership-section-image-conatiner{
  padding-left: 20px;
  padding-right: 20px;
}

.enterprise-partners-section-conatiner-image{
  width: 190px;
  margin:10px 80px 50px 20px;
}

@media (max-width: 768px) {
  /* Adjust the styles to fit smaller screens */
  .enterprise-consultative-section-conatiner{
    margin-top: 20px;
  }
  /* Reduce margin and padding to make the images fit better on small screens */
  .enterprise-partnership-section-image-conatiner {
    padding-left: 10px;
    padding-right: 10px;

  }

  .enterprise-partners-section-conatiner-image {
    width: 100%;
    max-width: 150px; /* Adjust the maximum width of the images */
    margin: 0px 40px;
     /* Adjust the margin */
  }
  
  .enterprise-partnership-section-title {
    font-size: 28px;
    line-height: 40px;
  }

  .enterprise-partners-section-button{
    padding: 6px 12px;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }
  .enterprise-consultative-section-card-container-content-title{
    font-size:20px;
    line-height: 32px;
  }
}

.enterprise-business-section-container{
  max-width: 100vw;
  margin-top: 40px;
  margin: 10px 50px 10px 50px;
  border-radius: 10px;
  display: flex;
}

.enterprise-business-section-container-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background-color: #f8f8f8;
}

.enterprise-business-section-subtitle{
    margin-bottom: 8px;
    color: #196ae5;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .15px;
    margin-bottom: 8px;
    font-weight: 600;
}
.enterprise-business-section-title{
  color: black;
    font-size: 32px;
    line-height: 60px;
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: .25px;
}

.enterprise-business-section-pargargh{
    font-size: 16px;
    line-height: 32px;
    color: rgba(0,0,0,0.6);
    margin-top: 16px;
    font-weight: 400;
}
.enterprise-business-section-player-conatiner{
  align-self: center;
  padding: 10px;
}
@media (max-width: 912px) {
  .enterprise-business-section-container {
    flex-direction: column-reverse;
    align-items: center;
    margin: 20px 10px;
  }
}
@media (max-width: 768px) {
  .enterprise-business-section-container {
    flex-direction: column;
    align-items: center;
    margin: 20px 10px;
  }

  .enterprise-business-section-container-content {
    width: 100%;
    text-align: center;
  }


  .enterprise-business-section-title {
    font-size: 28px;
    line-height: 40px;
    font-weight:600;
  }

  .enterprise-business-section-pargargh {
    font-size: 14px;
    line-height: 24px;
  }
  .enterprise-business-section-video{
    display: none;

  }
}

.enterprise-media-section-container{
  width: 100%;
  margin-top: 40px;
  padding-right: 3%;
  padding-left: 3%;
}

.enterprise-media-section-cards-container{
  display: flex;
}
.enterprise-media-section-container-card{
padding-top: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  width: 250px;
  height: 350px;
  border-radius: 10px;
}

.enterprise-media-section-container-card-image{
  width: 100%;
}

.enterprise-media-section-container-card-content{
  font-size: 16px;
    line-height: 28px;
    letter-spacing: .15px;
    color: #000;
    font-weight: 400;
}

.enterprise-media-section-container-card-date{
  line-height: 1.6em;
  font-weight: 400;
  font-size: 12px;
}
.enterprise-business-section-button-conatiner{
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
}
.enterprise-business-section-button-conatiner-button{
background-color: #f7fbfb;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
border-radius: 50%;
margin: 10px;
border: none;
}

@media screen and (max-width:767px) {
  .enterprise-media-section-cards-container{
    display: flex;
    flex-direction: column;
  }
  .enterprise-media-section-cards-container{
    margin: auto;
    align-items: center;
  }
  .enterprise-consultative-section-card-image-container{
    width:50px;
    height:50px;
    font-size:10px;
  }
}

.enterprise-awards-section-container{
  width: 100%;
  margin-top: 40px;
  padding-right: 3%;
  padding-left: 3%;
}

.enterprise-awards-section-container-card-image{
width:70%;
}