.courseReviewCard{
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #ffffff;
    box-shadow: #bfbfbf 4px 4px 4px 4px;
    width:350px;
    padding:30px;
}

.courseReviewFirstContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.courseReviewProfileImage{
    width:50px;
    height:50px;
    border-radius:50%;
    align-self: flex-start;
}
.courseReviewName{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
}
.courseReviewDesignation{
    margin-top: 5px;
    font-size: 14px;
}
.courseLinkedInImage{
    width:30px;
    height:30px;
    align-self: start;
}
.courseReviewDescripation{
    font-size: 14px;
}
.courseReviewTime{
    font-size: 14px;
}

@media (max-width:767px){
    .courseReviewName{
        font-size: 16px;
    }
    .courseReviewDesignation{
        font-size: 14px;
    }
    .courseReviewDescripation{
        font-size: 12px;
    }
    .courseReviewTime{
        font-size: 12px;
    }
}