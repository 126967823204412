.nav-explore-course-main-container{
    width:500px;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    list-style-type: none;
    padding:40px 40px;
    gap:10px;
}
.nav-explore-course-main-item-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    border:1px solid grey;
    padding:6px 12px;
    gap:10px;
}
.nav-explore-course-left-item-container{
    display:flex;
    align-items: center;
    gap:10px;
}
.nav-explore-course-main-container-item{
    font-family:"Roboto";
    font-size:16px;
    text-decoration:none;
    color:#222;
}
.nav-explore-status-text{
    background-color:#222;
    color:#fff;
    border-radius:25px;
    padding:6px 12px;
    font-size:12px;
    font-family:"Roboto";
    margin:0px;
}
.nav-explore-timer-icon{
    font-size:14px;
    color:#196AE5;
    font-weight:900;
    margin-left:3px;
}
.nav-explore-duration{
    font-size:16px;
    font-family:"Roboto";
    margin:0px;
    color:#222;
    display:flex;
    align-items:center;
}
.nav-explore-course-main-container-item-icon{
    font-size:16px;
    color:#222;

}