@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');

.Carousel-card-main-container{
    display:flex;
    justify-content: space-around;
    align-items:center;
    margin-top: 10px;
    margin-bottom: 0px;
    width:100%;
}
.Carousel-card-left-container{
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    width:40%;
    gap:20px;
}
.Carousel-card-left-title{
    font-size:16px;
    font-family:"poppins,sans-serif";
    color:#196AE5;
}
.Carousel-card-left-heading{
    color:#000000DE;
    font-size:42px;
    font-family:"Roboto";
    margin:8px 0px 0px;
    font-weight:600;
}
.Carousel-card-left-text{
    color:#222;
    font-size:24px;
    font-family:"Roboto";
    margin:8px 0px 0px;
    font-weight:500;
}
.Carousel-card-left-desc{
    color:#222;
    font-size:16px;
    font-family:"Roboto";
    margin:8px 0px 8px;
}
.Carousel-card-left-button{
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(#FFF, #FFF) padding-box, linear-gradient(to right,#7cb4e4,#1c74d4,#0e39a9) border-box;
    transition: all .5s ease;
    border: 2.5px solid transparent;
    border-radius: 10px;
    background-color:transparent;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 1.25px;
    color: #222;
    padding: 16px 24px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}
.Carousel-card-left-button:hover {
    background: linear-gradient(to right, #196ae5, #196ae5) border-box;
    border: 3px solid transparent;
    color: #FFF;
    transition: all .1s ease;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay:0s;
  
  }
@media (max-width:972px){
        .Carousel-card-left-container{
            width:55%;
            gap:0px;
        }
        .Carousel-card-left-heading{
            font-size:14px;
        }
        .Carousel-card-left-desc{
            font-size:10px;
        }
        .Carousel-card-left-text{
            font-size:12px;
        }
        .Carousel-card-right-image{
            width:50px;
        }
}
@media (max-width:768px){
    .Carousel-card-left-button{
        padding:5px 10px;
        font-size:12px;
        border-radius: 7px;
        border: 1.5px solid transparent;
    }
}
@media (max-width:512px){
    .Carousel-card-left-heading{
        font-size:24px;
        text-align: center;
    }
    .Carousel-card-left-text{
        font-size:18px;
        margin: 10px;
        margin-top: 20px;
        text-align: center;
    }
    .Carousel-card-left-desc{
        margin-top: 20px;
        font-size:14px;
        text-align: center;
    }
    .Carousel-card-left-button{
        margin: 10px;
        margin-top: 20px;
        padding:10px 10px;
        font-size:14px;
    }
}

.Carousel-card-right-image{
    width:30%;
}
@media (max-width:512px){
    .Carousel-card-main-container{
        flex-direction: column-reverse;
        align-items:center;
        min-height:80vh;
        gap:20px;
    }
    .Carousel-card-left-container{
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        height:40vh;
    }
    .Carousel-card-right-image{
        width:80%;
        
    }
    
}